$componentWidth: 10px;
$textSpanWidth: 55px;
$circleLeft: 75px;


.wrgToggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.wrgToggleInput {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wrgToggleCheck, .wrgToggleUncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity .25s ease;
}
.wrgToggleCheck {
  left: 8px;
}
.wrgToggleUncheck {
  opacity: 1;
  right: 10px;
}

.wrgToggleUncheck span, 
.wrgToggleCheck span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: left;
  position: relative;
  width: $textSpanWidth;
}

.wrgToggleContainer{
  width: $componentWidth;
  height: 25px;
  margin-top: 3px;
  border-radius: 30px;
  background-color: #4d4d4d;
  color: white;
  transition: all .2s ease;
}

.wrgToggleCircle{
  transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
  position: absolute;
  top: 4px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all .25s ease;
}

.wrgToggleChecked .wrgToggleCheck{
  opacity: 1;
}
.wrgToggleChecked .wrgToggleUncheck {
  opacity: 0;
}
.wrgToggleChecked .wrgToggleCircle {
  left: $circleLeft;
}

.wrgToggleDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}